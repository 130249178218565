import React from "react";
import { motion } from "framer-motion";
import FacebookIcon from "assets/images/facebook.svg";
import TwitterIcon from "assets/images/twitter.svg";
import InstagramIcon from "assets/images/instagram.svg";
import TelegramIcon from "assets/images/telegram.svg";

const SocialLink = () => {
  return (
    <div className="h-screen fixed hidden md:flex flex-col items-center justify-center top-0 right-16 gap-12 z-20">
      
      <div className="h-20">
        <motion.span
          initial={{ height: 0 }}
          whileInView={{ height: 80 }}
          className="block w-[1px] bg-[#E3E3E3]"
        />
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.9 }}
      >
        <a href="https://t.me/Lumwave" target="_blank" rel="noopener noreferrer">
          <img
            src={TelegramIcon}
            className="cursor-pointer hover:opacity-50 transition"
          />
        </a>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <a href="https://twitter.com/LumwaveX" target="_blank" rel="noopener noreferrer">
          <img
            src={TwitterIcon}
            className="cursor-pointer hover:opacity-50 transition"
          />
        </a>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.7 }}
      >
        <a href="https://www.instagram.com/lumwave.io/" target="_blank" rel="noopener noreferrer">
          <img
            src={InstagramIcon}
            className="cursor-pointer hover:opacity-50 transition"
          />
        </a>
      </motion.div>


      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <a href="https://www.facebook.com/people/Lumwave/61556162531140/" target="_blank" rel="noopener noreferrer">
          <img
            src={FacebookIcon}
            className="cursor-pointer hover:opacity-50 transition"
          />
        </a>
      </motion.div>



      <div className="h-20">
        <motion.span
          initial={{ height: 0 }}
          whileInView={{ height: 80 }}
          transition={{ delay: 1.2 }}
          className="block w-[1px] bg-[#E3E3E3]"
        />
      </div>
      
    </div>
  );
};

export default SocialLink;

