import React from "react";

const Logo = ({ width = 57, color = "#f6682b" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 57 57"
    >
      <g
        id="Group_55968"
        data-name="Group 55968"
        transform="translate(-127.388 65.214)"
      >
        <rect
          id="Rectangle_19868"
          data-name="Rectangle 19868"
          width="55"
          height="55"
          rx="27.5"
          transform="translate(128.388 -64.214)"
          fill="none"
          stroke={color}
          stroke-width="2"
          opacity="0.81"
        />
        <g id="Group_56006" data-name="Group 56006">
          <path
            id="Path_48875"
            data-name="Path 48875"
            d="M160.046-30.4h12.527s-1.363,1.172-1.9,1.746a9.672,9.672,0,0,0-.877,1.068l-.224.409v15.545a5.707,5.707,0,0,0,2.378.537,12.182,12.182,0,0,0,4.079-.714,22.755,22.755,0,0,0,2.541-1.357c.644-.476,2.816-1.829,2.816-1.829a31.332,31.332,0,0,1-1.211,3.953c-.558,1.236-2.02,4.737-2.02,4.737H158.933l3.342-2.142a6.041,6.041,0,0,0,.96-.838,1.175,1.175,0,0,0,.257-.866V-27.085a1.627,1.627,0,0,0-.542-1.081C162.455-28.54,160.046-30.4,160.046-30.4Z"
            transform="translate(-14.269 -18.642)"
            fill={color}
          />
          <path
            id="Path_48883"
            data-name="Path 48883"
            d="M149.2-40.417v4.656l6.1-3.644v-4.656Z"
            fill="#221d23"
          />
          <path
            id="Path_48884"
            data-name="Path 48884"
            d="M149.2-40.417v2.775l6.1-3.694v-2.725Z"
            transform="translate(0 7)"
            fill="#221d23"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
