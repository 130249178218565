import React from "react";
import { motion } from "framer-motion";
import ScopulyImage from "assets/images/scopuly.png";
import StellarXImage from "assets/images/stellar-x.png";
import StellarTermImage from "assets/images/stellar-term.png";
import LobstrImage from "assets/images/lobstr.png";
import InterstellarImage from "assets/images/interstellar.png";

const Sponser = () => {
  return (
    <div className="pt-0 md:pt-7 mb-10 md:mb-[120px] px-5" id="sponser">
      <div className="w-full md:h-[100px] max-w-container mx-auto grid grid-cols-2 md:grid-cols-5">
        
        <motion.div
          className="h-24 flex flex-shrink-0 items-center justify-center"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "backOut" }}
        >
          <a href="https://lobstr.co/trade/LMW:GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW" target="_blank" rel="noopener noreferrer">
            <img
              src={LobstrImage}
              className="scale-75 hover:opacity-50 transition cursor-pointer"
            />
          </a>
        </motion.div>

        <motion.div
          className="h-24 flex flex-shrink-0 items-center justify-center"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "backOut", delay: 0.2 }}
        >
          <a href="https://stellarterm.com/exchange/LMW-GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW/XLM-native" target="_blank" rel="noopener noreferrer">
            <img
              src={StellarTermImage}
              className="scale-75 hover:opacity-50 transition cursor-pointer"
            />
          </a>
        </motion.div>


        <motion.div
          className="h-24 flex flex-shrink-0 items-center justify-center"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "backOut", delay: 0.6 }}
        >
          <a href="https://scopuly.com/trade/LMW-XLM/GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW/native" target="_blank" rel="noopener noreferrer">
            <img
              src={ScopulyImage}
              className="scale-75 hover:opacity-50 transition cursor-pointer"
            />
          </a>
        </motion.div>

        <motion.div
          className="h-24 flex flex-shrink-0 items-center justify-center"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "backOut", delay: 0.8 }}
        >
          <a href="https://www.stellarx.com/markets/LMW:GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW/native" target="_blank" rel="noopener noreferrer">
            <img
              src={StellarXImage}
              className="scale-75 hover:opacity-50 transition cursor-pointer"
            />
          </a>
        </motion.div>

        <motion.div
          className="h-24 flex flex-shrink-0 items-center justify-center"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "backOut", delay: 0.4 }}
        >
          <a href="https://interstellar.exchange/app/#/trade/guest/LMW/GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW/XLM/native" target="_blank" rel="noopener noreferrer">
            <img
              src={InterstellarImage}
              className="scale-75 hover:opacity-50 transition cursor-pointer"
            />
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default Sponser;
