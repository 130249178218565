import React, { useState } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import useIsMobile from "hooks/useIsMobile";
import ClientAvatar from "assets/images/james.png";
import ClientAvatar1 from "assets/images/rebecca.png";
import ClientAvatar2 from "assets/images/robert.png";
import ClientAvatar3 from "assets/images/sarah.png";
import ClientAvatar4 from "assets/images/william.png";
import StarIcon from "assets/images/star.svg";
import StarFillIcon from "assets/images/star-fill.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Rating = ({ value }) => {
  return (
    <div className="flex gap-1">
      {Array.from(Array(value)).map((key, index) => (
        <img src={StarFillIcon} className="w-5 h-5" key={index} />
      ))}
      {Array.from(Array(5 - value)).map((key, index) => (
        <img src={StarIcon} className="w-5 h-5" key={index} />
      ))}
    </div>
  );
};

const Card = ({ client, date, description, mark }) => {
  return (
    <motion.div
      className="max-w-[450px] bg-white rounded-2xl p-6"
      initial={{ scale: 0 }}
      whileInView={{ scale: 1 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div className="pb-4 border-b border-b-[#C0C0C0] text-black mb-3">
        <p className="text-base text-[#C5C5C5] text-right mb-2 leading-loose tracking-[-0.02em]">
          Date: {date}
        </p>
        <p className="text-sm md:text-base leading-5 md:leading-6">
          Original review: {description}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <Rating value={mark} />
        <div className="flex items-center gap-3">
          <img
            src={client.avatar}
            className="w-10 md:w-12 h-10 md:h-12 rounded-full flex-shrink-0"
          />
          <div className="flex flex-col text-black tracking-[-0.02em]">
            <span className="font-semibold text-base md:text-lg leading-6">
              {client.name}
            </span>
            <span className="font-light text-sm leading-5 opacity-50">
              {client.role}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Feedback = () => {
  const isMobile = useIsMobile();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const data = [
    {
      id: 1,
      client: {
        name: "James Smith",
        role: "Blockchain Developer",
        avatar: ClientAvatar,
      },
      date: "August 9, 2023",
      description:
        "Lumwave's integration with Stellar is exceptionally impressive. It offers a wide range of services and boasts a user-friendly liquidity manager. A must-have for anyone navigating the crypto world. A solid 5-star rating!",
      mark: 5,
    },
    {
      id: 2,
      client: {
        name: "Rebecca Johnson",
        role: "Crypto Analyst",
        avatar: ClientAvatar1,
      },
      date: "January 28, 2024",
      description:
        "Lumwave is a versatile token with vast potential. It can be a bit overwhelming for beginners, but the passive income opportunities are excellent. I'd rate it 4 stars for its promising features.",
      mark: 4,
    },
    {
      id: 3,
      client: {
        name: "Robert Brown",
        role: "Cryptocurrency Trader",
        avatar: ClientAvatar2,
      },
      date: "January 6, 2024",
      description:
        "Lumwave is promising, but its complexity can be a barrier for new users. The liquidity manager is handy, but the user experience needs improvement. I give it 3 stars for its potential.",
      mark: 3,
    },
    {
      id: 4,
      client: {
        name: "Sarah Miller",
        role: "Blockchain Consultant",
        avatar: ClientAvatar3,
      },
      date: "Nobember 3, 2023",
      description:
        "Lumwave is an outstanding asset in the crypto world! Its seamless integration with various ecosystems and the provision of valuable features make it deserving of a solid 5-star rating for its efficiency and utility.",
      mark: 5,
    },
    {
      id: 5,
      client: {
        name: "William Turner",
        role: "NFT Creator",
        avatar: ClientAvatar4,
      },
      date: "December 19, 2023",
      description:
        "Lumwave shows great potential in the NFT space. The cross-border transaction capability is a game-changer. It could improve by offering more artist-friendly features, earning it 4 stars.",
      mark: 4,
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => {
      setSelectedIndex(index);
    },
  };

  return (
    <div className="mb-10 md:mb-[105px] px-5">
      <div className="w-full max-w-container mx-auto">
        <motion.p
          className="max-w-[560px] font-bold text-[40px] md:text-[48px] leading-[48px] md:leading-[56px] tracking-[0.05em] mb-5 md:mb-14 uppercase"
          initial={{ x: -300, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "backOut" }}
        >
          Analytics Feedback
        </motion.p>
        <div className="mb-8 md:mb-14">
          <Slider {...settings}>
            {data.map((item) => (
              <Card key={item.id} {...item} />
            ))}
          </Slider>
        </div>
        <div className="flex justify-center">
          <div className="flex w-[234px] h-[5px] bg-[#9B9B9B]">
            {Array.from(Array(data.length)).map((key, index) => (
              <div
                key={index}
                className={`w-full h-full ${
                  index === selectedIndex && `bg-white`
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
