import React from "react";
import { motion } from "framer-motion";
import LineImage from "assets/images/line.png";
import VerticalLineImage from "assets/images/vertical-line.png";
import LineImage1 from "assets/images/line-1.png";

const RoadMap = () => {
  const data = {
    2023: [
      "Q1: Foundation and Development",
      "Q2: Utility Expansion and Partnerships",
      "Q3: Optimization and Feedback",
    ],
    2024: [
      "Q1: Ecosystem Building",
      "Q2: Staking and Governance Framework",
      "Q3: Continued Development and Sustainability",
    ],
    2025: null,
  };
  
  return (
    <div className="py-10 md:py-[105px] px-5" id="roadmap">
      <div className="w-full max-w-container mx-auto">
        <motion.h3
          className="font-bold text-[40px] md:text-[48px] text-center mb-8 md:mb-[72px] uppercase tracking-[.05em]"
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "backOut" }}
        >
          Our roadmap
        </motion.h3>
        <motion.div
          className="hidden md:block relative overflow-hidden"
          initial={{ width: 0 }}
          whileInView={{ width: "100%" }}
          transition={{ duration: 2 }}
        >
          <img src={LineImage} className="w-[1440px] absolute top-1/2" />
          <div className="w-[1440px] flex justify-between">
            {Object.keys(data).map((key) => (
              <React.Fragment key={key}>
                <div className="flex flex-col">
                  <div className="h-1/2 flex flex-col items-center justify-end relative">
                    <span className="inline-flex font-semibold text-lg text-[#F6682B] mb-8">
                      {key}
                    </span>
                    <span className="block w-4 h-4 bg-[#F6682B] absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2"></span>
                  </div>
                  <div className="h-1/2 flex flex-col items-center opacity-0 relative">
                    <span className="inline-flex font-semibold text-lg mb-5">
                      {key}
                    </span>
                    <span className="block w-4 h-4 bg-[#F6682B] absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2"></span>
                  </div>
                </div>
                {data[key]?.map((item, index) => {
                  const reverse2021 = key === "2023" && index === 1;
                  const reverse2022 = key === "2024";
                  return (
                    <div
                      className={`w-full flex flex-col ${
                        reverse2022
                          ? `even:flex-col-reverse`
                          : `odd:flex-col-reverse`
                      }`}
                      key={index}
                    >
                      <div
                        className={`h-1/2 flex ${
                          reverse2021 || (reverse2022 && index !== 1)
                            ? `flex-col-reverse`
                            : `flex-col`
                        } items-center justify-end relative`}
                      >
                        <span className="font-semibold text-xl text-center">
                          {item}
                        </span>
                        <img
                          src={VerticalLineImage}
                          className={`${
                            reverse2021 || (reverse2022 && index !== 1)
                              ? `mt-8 mb-6`
                              : `mt-6 mb-8`
                          }`}
                        />
                        <span
                          className={`block w-4 h-4 bg-[#F6682B] absolute left-1/2 -translate-x-1/2 ${
                            reverse2021 || (reverse2022 && index !== 1)
                              ? `top-0 -translate-y-1/2`
                              : `bottom-0 translate-y-1/2`
                          }`}
                        ></span>
                      </div>
                      <div className="h-1/2 flex flex-col items-center opacity-0 relative">
                        <span className="font-semibold text-xl text-center">
                          {item}
                        </span>
                        <img src={VerticalLineImage} className="mt-6 mb-8" />
                        <span className="block w-4 h-4 bg-[#F6682B] absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2"></span>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="block md:hidden relative overflow-hidden px-5"
        >
          <img
            src={LineImage1}
            className="h-full absolute top-0 left-[27.5px]"
          />
          <div className="flex flex-col gap-8 relative z-10">
            {Object.keys(data).map((key) => (
              <React.Fragment key={key}>
                <div className="flex gap-5 items-center">
                  <span className="block w-5 h-5 bg-[#F6682B]"></span>
                  <span className="inline-flex font-semibold text-2xl md:text-xl text-[#F6682B]">
                    {key}
                  </span>
                </div>
                {data[key]?.map((item, index) => {
                  return (
                    <div className="w-full flex" key={index}>
                      <div className="flex items-center gap-5">
                        <span className="block w-5 h-5 bg-[#F6682B]"></span>
                        <span className="font-semibold text-base md:text-xl leading-4">
                          {item}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default RoadMap;
