import React from "react";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import Footer from "./Footer";
import SocialLink from "./SocialLink";

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen bg-[#303030] font-poppins text-white overflow-x-hidden">
      <Header />
      <MobileHeader />
      <div>{children}</div>
      <Footer />
      <SocialLink />
    </div>
  );
};

export default Layout;
