import React, { useState } from "react";
import { motion } from "framer-motion";
import API from "apis";
import BgImage from "assets/images/newsletter.png";
import FacebookIcon from "assets/images/facebook.svg";
import TwitterIcon from "assets/images/twitter.svg";
import InstagramIcon from "assets/images/instagram.svg";
import TelegramIcon from "assets/images/telegram.svg";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const handleSubscribe = () => {
    API.subscribe({ email });
  };

  return (
    <div className="px-5">
      <div className="w-full max-w-container mx-auto relative rounded-2xl md:rounded-[32px] overflow-hidden">
        <img
          src={BgImage}
          className="absolute top-0 left-0 w-full h-full object-cover object-center"
        />
        <motion.div
          className="py-10 md:py-16 md:pb-20 relative z-10 px-5"
          initial={{ opacity: 0, y: 300 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "backOut" }}
        >
          <div className="flex justify-center items-center gap-8 md:gap-16 mb-5 md:mb-10">
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.8, ease: "backOut", delay: 1.9 }}
            >
              <a href="https://t.me/Lumwave" target="_blank" rel="noopener noreferrer">
                <img
                  src={TelegramIcon}
                  className="cursor-pointer hover:opacity-50 transition"
                />
              </a>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.8, ease: "backOut", delay: 1.3 }}
            >
              <a href="https://twitter.com/LumwaveX" target="_blank" rel="noopener noreferrer">
                <img
                  src={TwitterIcon}
                  className="cursor-pointer hover:opacity-50 transition"
                />
              </a>
            </motion.div>
            
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.8, ease: "backOut", delay: 1.6 }}
            >
              <a href="https://www.instagram.com/lumwave.io/" target="_blank" rel="noopener noreferrer">
                <img
                  src={InstagramIcon}
                  className="cursor-pointer hover:opacity-50 transition"
                />
              </a>
            </motion.div>

            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.8, ease: "backOut", delay: 1 }}
            >
              <a href="https://www.facebook.com/people/Lumwave/61556162531140/" target="_blank" rel="noopener noreferrer">
                <img
                  src={FacebookIcon}
                  className="cursor-pointer hover:opacity-50 transition"
                />
              </a>
            </motion.div>
            


          </div>
          <div className="flex flex-col items-center">
            <p className="max-w-[658px] font-bold text-3xl md:text-[40px] tracking-[0.05em] text-center leading-8 md:leading-[48px] mb-4 uppercase">
              Stay Informed with Our Updates
            </p>
            <p className="max-w-[600px] text-center text-sm md:text-base leading-4 md:leading-5 mb-5 md:mb-8">
             If you're passionate about blockchain technology and its applications, Lumwave News can provide insights into how Lumwave integrates with blockchain and its impact.
            </p>
            <div className="w-full max-w-[642px] flex flex-col gap-4 relative">
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full h-16 bg-[#FFFFFF18] rounded-[17px] px-6 font-semibold text-lg placeholder:text-white outline-none md:pr-[200px] text-center md:text-left"
              />
              <motion.button
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ ease: "backOut", delay: 1 }}
                className="relative md:absolute h-16 top-0 right-0 px-6 rounded-[17px] font-semibold text-lg flex-shrink-0 bg-gradient-to-bl from-[#D64709] to-[#F6682B] hover:opacity-50 transition"
                onClick={handleSubscribe}
              >
                Subscribe Now
              </motion.button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default NewsLetter;
