import React from "react";
import Logo from "assets/images/logo.svg";

const Footer = () => {
  return (
    <div className="bg-[#424242] py-5">
      <div className="w-full max-w-container mx-auto">
        <div className="flex flex-col md:flex-row gap-5 items-center justify-between">
          <div className="flex items-center">
            <img src={Logo} className="w-12 h-12 md:w-14 md:h-14" alt="lumwave" />
            <span className="ml-5 md:ml-9 font-semibold text-lg md:text-xl tracking-[.6em]">
              LUMWAVE | LMW
            </span>
          </div>
          <span className="font-semibold text-lg">
            Copyright © Lumwave 2023
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
