import React from "react";
import Layout from "components/Layout";
import Hero from "components/Hero";
import Sponser from "components/Sponser";
import AboutUs from "components/AboutUs";
import Feedback from "components/Feedback";
import NewsLetter from "components/NewsLetter";
import RoadMap from "components/RoadMap";

const App = () => {
  return (
    <Layout>
      <Hero />
      <Sponser />
      <AboutUs />
      <Feedback />
      <NewsLetter />
      <RoadMap />
    </Layout>
  );
};

export default App;
