import React from "react";
import { motion } from "framer-motion";
import Countdown from "react-countdown";
import BannerImage from "assets/images/banner.png";
import PhoneImage from "assets/images/phone.png";
import ButtonImage from "assets/images/buy-btn.png";
import DotIcon from "assets/images/dot.svg";

const timerRenderer = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="flex items-start justify-between font-medium text-[40px] md:text-[50px] leading-[48px] md:leading-[64px]">
      <div className="flex flex-col items-center">
        <span className="inline-block w-12 md:w-[100px] text-center mb-1">{days}</span>
        <span className="font-light text-sm leading-5 uppercase">Day</span>
      </div>
      <img src={DotIcon} className="mt-3 md:mt-7" />
      <div className="flex flex-col items-center">
        <span className="inline-block w-12 md:w-[100px] text-center mb-1">{hours}</span>
        <span className="font-light text-sm leading-5 uppercase">Hour</span>
      </div>
      <img src={DotIcon} className="mt-3 md:mt-7" />
      <div className="flex flex-col items-center">
        <span className="inline-block w-12 md:w-[100px] text-center mb-1">
          {minutes}
        </span>
        <span className="font-light text-sm leading-5 uppercase">Min</span>
      </div>
      <img src={DotIcon} className="mt-3 md:mt-7" />
      <div className="flex flex-col items-center">
        <span className="inline-block w-12 md:w-[100px] text-center mb-1">
          {seconds}
        </span>
        <span className="font-light text-sm leading-5 uppercase">Sec</span>
      </div>
    </div>
  );
};

const Hero = () => {
  return (
    <div className="relative">
      <img
        src={BannerImage}
        className="absolute w-full h-full object-cover left-0 top-0"
      />
      <div className="absolute w-full h-full left-0 top-0 bg-gradient-to-b from-transparent to-[#303030]"></div>
      <div className="w-full max-w-container mx-auto relative z-10 pt-24 md:pt-[180px] pb-8 md:pb-16 px-5">
        <div className="flex flex-col md:flex-row">
          <motion.div
            className="w-full md:w-1/2"
            initial={{ x: -350, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "backOut" }}
          >
            <div style={{ marginTop: '70px' }}>
              <p className="font-extrabold text-[40px] leading-[48px] md:text-[48px] md:leading-[72px] tracking-[0.03em] uppercase mb-30">
                Wave by Wave
              </p>
            </div>
            <p className="font-extrabold text-[40px] leading-[45px] md:text-[48px] md:leading-[72px] tracking-[0.03em] uppercase mb-30">
              Building a Sustainable Ecosystem
            </p>
            <p className="max-w-[686px] font-light text-base md:text-lg opacity-80 mb-4 md:mb-8">
Lumwave is built on Stellar, chosen for its lightning-fast transactions, low costs, security, and decentralization. Started on Stellar, our token leverages its proven track record, compliance features, and interoperability, promising a reliable, efficient, and community-focused ecosystem for all users. 
            </p>
            <div className="flex flex-col items-center md:flex-row md:justify-between">
              <button
                className="relative flex items-center justify-center w-full md:w-auto rounded-[17px] overflow-hidden mb-6 md:mb-0 hover:opacity-50 transition"
                style={{ boxShadow: "0 0 50px 0 #F6682B", marginBottom: "45px" }}
                onClick={() => window.location.href = "https://lobstr.co/trade/LMW:GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW"}
              >
                <img src={ButtonImage} className="w-full h-[66px]" />
                <span className="absolute font-semibold text-lg md:text-xl">
                  Trade on LOBSTR
                </span>
              </button>
            
              <button
                className="relative flex items-center justify-center w-full md:w-auto rounded-[17px] overflow-hidden mb-6 md:mb-0 hover:opacity-50 transition"
                style={{ boxShadow: "0 0 50px 0 #F6682B", marginBottom: "45px" }}
                onClick={() => window.location.href = "https://t.me/Lumwave"}
              >
                <img src={ButtonImage} className="w-full h-[66px]" />
                <span className="absolute font-semibold text-lg md:text-xl">
                  Join our Telegram
                </span>
              </button>
            </div>


          </motion.div>
          <motion.div
            className="w-full md:w-1/2 flex items-start justify-center"
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "backOut", delay: 0.5 }}
          >
            <img src={PhoneImage} className="max-w-none w-[480px] md:w-[640px]" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
