import React, { useState } from "react";
import { animateScroll } from "react-scroll";
import OutsideClickHandler from 'react-outside-click-handler';
import Logo from "components/Logo";
import ProfilBg from "assets/images/profile-bg.png";

const Header = () => {
  const [opened, setOpened] = useState(false);
  const handleScroll = (element) => {
    animateScroll.scrollTo(
      document.getElementById(element).getBoundingClientRect().top - 80
    );
  };

  return (
    <div className="hidden md:block absolute z-20 left-0 right-0">
      <div className="w-full max-w-container mx-auto py-11">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Logo />
            <span className="ml-6 mr-[72px] font-semibold text-xl tracking-[.6em]">
              Lumwave
            </span>
            <span className="w-[1px] h-4 bg-[#979797] opacity-[0.74]"></span>
          </div>
          <div className="flex gap-14 text-base tracking-[.3em]">
            <span
              className="hover:opacity-50 transition cursor-pointer"
              onClick={() => handleScroll("sponser")}
            >
              Buy Lumwave
            </span>
            <span
              className="hover:opacity-50 transition cursor-pointer"
              onClick={() => handleScroll("about-us")}
            >
              Whitepaper
            </span>
            <span
              className="hover:opacity-50 transition cursor-pointer"
              onClick={() => handleScroll("roadmap")}
            >
              Roadmap
            </span>
            <a
              href="https://www.t.me/lumwave"
              className="hover:opacity-50 transition cursor-pointer"
              target="_blank" rel="noopener noreferrer"
            >
              Telegram
            </a>
          </div>
          <div className="flex items-center gap-12">
            <a href="https://lobstr.co/trade/LMW:GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW" target="_blank" rel="noopener noreferrer">
              <div
                className="w-[280px] h-[66px] relative cursor-pointer rounded-[17px] overflow-hidden hover:opacity-50 transition"
                style={{ boxShadow: "0 30px 50px #00000070" }}
              >
                <img src={ProfilBg} className="absolute w-full h-full top-0 left-0" />
                <div className="w-full h-full flex items-center relative uppercase gap-5 pl-3.5 pr-6 bg-[#287B5CCF] z-10">
                  <Logo width={48} color="#59DBB5" />
                  <div className="w-full">
                    <p className="text-base">
                      <span className="fot-semibold tracking-[.2em]">
                        lumwave
                      </span>
                      <span className="inline-block w-[1px] h-[13px] bg-white mx-3"></span>
                      <span className="font-light ">LMW</span>
                    </p>
                    <p className="flex justify-between text-sm">
                      <span className="font-light">0.0003813 usd</span>
                      <span className="text-[#59DBB5]">+ 3.10%</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
