import React, { useState } from "react";
import { motion } from "framer-motion";
import ButtonImage from "assets/images/buy-btn.png";
import MoreIcon from "assets/images/more.svg";
import WalletIcon from "assets/images/wallet.svg";
import CheckboxIcon from "assets/images/checkbox.svg";

const Card = ({ title, description }) => {
  return (
    <motion.div
      className="flex items-start gap-5 md:gap-10"
      initial={{ scale: 0 }}
      whileInView={{ scale: 1 }}
      transition={{ duration: 0.8, ease: "backOut" }}
    >
      <img src={CheckboxIcon} className="w-10 h-10 md:w-[50px] md:h-[50px]" />
      <div>
        <p className="font-semibold text-xl md:text-2xl mb-1 md:mb-2">
          {title}
        </p>
        <p className="font-light text-base md:text-lg text-[#BEBEBE]">
          {description}
        </p>
      </div>
    </motion.div>
  );
};

const WalletCard = ({ avatar, name, title, description }) => {
  return (
    <motion.div
      initial={{ scale: 0 }}
      whileInView={{ scale: 1 }}
      transition={{ duration: 0.8, ease: "backOut" }}
      className="rounded-2xl md:rounded-[33px] p-5 md:p-10 md:pt-9 bg-gradient-to-bl from-[#FF267D30] to-[#A5502C30]"
    >
      <div className="flex items-center gap-4 md:gap-6 mb-3">
        <img src={avatar} className="w-10 h-10 md:w-[50px] md:h-[50px]" />
        <span className="font-light text-lg md:text-xl">{name}</span>
      </div>
      <p className="max-w-[448px] font-semibold text-xl leading-6 md:text-2xl md:leading-8 mb-4 uppercase">
        {title}
      </p>
      <p className="font-light text-sm md:text-base leading-5 md:leading-6 opacity-[0.85] mb-4">
        {description}
      </p>
    </motion.div>
  );
};

const AboutUs = () => {
  const [showPopup, setShowPopup] = useState(false); // State variable for controlling popup visibility

  const togglePopup = () => {
    setShowPopup(!showPopup); // Toggle the state variable to show/hide the popup
  };

  const wallets = [
     {
      id: 1,
      avatar: WalletIcon,
      name: "Cross-Platform Utility",
      title: "One Asset, Limitless Possibilities",
      description:
"Discover the power of Lumwave, the driving force behind a revolutionary experience on the Stellar network. Unlocks a decentralized wallet, empowering users to effortlessly trade assets and develop a diverse range of applications. What makes us stand out? Our wallet introduces an unprecedented feature: seamless NFT trading, including the creation and launch of collections, extending possibilities far beyond the confines of the Stellar network.",
},
    {
      id: 2,
      avatar: WalletIcon,
      name: "Earning Engine",
      title: "Innovative Passive Income Solutions",
      description:
"Your exclusive portal to the world of passive income and financial empowerment, Lumwave presents unparalleled APY rates, with payments seamlessly processed in Stellar (XLM), ranging from 7% to 49%, contingent upon the volume of LMW held in your portfolio, while our platform ensures careful risk management. Immerse yourself in cutting-edge blockchain protocols, where security and great rewards converge. Furthermore, by strategically reinvesting your earnings, you'll exponentially amplify your gains, paving the path for unprecedented prosperity and financial growth.",
},
    {
      id: 3,
      avatar: WalletIcon,
      name: "Lumwave: ProfitGuard", 
      title: "Empowering Your Investment Journey",
      description:
        "Effortlessly track your crypto earnings in real-time with our platform. Monitor profits at any interval and identify low-risk assets for secure investments, while seizing high-gain opportunities with elevated risk. Our innovative tools empower you to stay ahead of market trends, maximizing your investment potential effortlessly. Additionally, our comprehensive analytics provide invaluable insights for informed decision-making. Experience peace of mind knowing that our platform is designed to cater to your financial needs, ensuring a seamless and profitable crypto investment journey.",
    },

  ];

  const data = [
    {
      id: 1,
      title: "Decentralized Wallet",
      description:
        "Seamlessly trade assets, including NFTs, on Stellar network with Lumwave's innovative wallet solution.",
    },
    {
      id: 2,
      title: "Real-Time Profit Tracking",
      description:
        "Monitor earnings effortlessly, identify low-risk assets, and seize high-gain opportunities in real-time.",
    },
    {
      id: 3,
      title: "High APY Rates",
      description:
        "Lumwave offers unparalleled APY rates ranging from 7% to 49%, with payments seamlessly processed in Stellar (XLM).",
    },
    {
      id: 4,
      title: "Global Accessibility",
      description:
        "Lumwave provides access to financial opportunities for users worldwide, transcending geographical barriers and promoting financial inclusion on a global scale.",
    },
    {
      id: 5,
      title: "Vault-Like Data Encryption",
      description:
        "Rest assured with Lumwave's vault-like data encryption, ensuring the utmost confidentiality and security for your sensitive information.",
    },
    {
      id: 6,
      title: "Transparent Fees",
      description:
        "With competitive rates and no hidden charges, providing users with clarity and confidence in their financial transactions.",
    },
  ];


  return (
    <div className="mb-10 md:mb-[105px] px-5" id="about-us">
      <div className="w-full max-w-container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10 mb-10 md:mb-[105px]">
          <motion.div
            initial={{ x: -300, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, ease: "backOut" }}
          >
            <p className="font-semibold text-[40px] md:text-[45px] mb-4 md:mb-5 leading-[48px] md:leading-[56px]">
              Our Mission and Vision
            </p>
            <p className="font-light text-base md:text-lg opacity-80 leading-6 mb-4 md:mb-10">
              Lumwave extends beyond being a mere transactional asset; it offers
              a wide range of applications within the Stellar ecosystem, tapping
              into its compliance features, interoperability, and established
              partnerships. Lumwave isn't just an asset, it's a gateway to a
              versatile and promising decentralized future.
            </p>
            <button
              className="w-full md:w-auto h-[66px] relative flex items-center justify-center rounded-[17px] overflow-hidden hover:opacity-50 transition"
              style={{ boxShadow: "0 0 50px 0 #F6682B" }}
              onClick={() => window.location.href = 'https://lumwave.gitbook.io/'} // Redirect to google.com
            >
              <img src={ButtonImage} className="w-full h-full" alt="Button Image" />
              <span className="absolute font-semibold text-lg md:text-xl">
                Whitepaper
              </span>
            </button>
          </motion.div>
          {wallets.map((item) => (
            <WalletCard key={item.id} {...item} />
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-11">
          {data.map((item) => (
            <Card key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
