import React, { useState } from "react";
import { animateScroll } from "react-scroll";
import OutsideClickHandler from "react-outside-click-handler";
import Logo from "components/Logo";
import ProfilBg from "assets/images/profile-bg.png";
import MenuIcon from "assets/images/menu.png";
import CloseIcon from "assets/images/close.png";

const MobileHeader = () => {
  const [opened, setOpened] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const handleToggleOpen = (e, element) => {
    setOpened(!opened);
    if (element) {
      animateScroll.scrollTo(
        document.getElementById(element).getBoundingClientRect().top - 80
      );
    }
  };

  return (
    <div className="block md:hidden absolute z-20 left-0 right-0">
      <div className="w-full max-w-container mx-auto p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Logo />
            <span className="ml-4 font-semibold text-lg tracking-[.6em]">
              Lumwave
            </span>
          </div>
          <img
            src={MenuIcon}
            onClick={handleToggleOpen}
            className="w-6 invert"
          />
        </div>
      </div>
      <div
        className={`fixed top-0 left-0 w-screen bg-[#303030] h-screen pt-24 p-10 ${
          opened ? `translate-x-0` : `-translate-x-full`
        } transition`}
      >
        <img
          src={CloseIcon}
          onClick={handleToggleOpen}
          className="w-4 invert absolute right-6 top-9"
        />
        <div className="flex flex-col gap-5 text-base tracking-[.3em] mb-10">
          <span
            onClick={(e) => handleToggleOpen(e, "sponser")}
            className="hover:opacity-50 transition"
          >
            Buy Lumwave
          </span>
          <span
            onClick={(e) => handleToggleOpen(e, "about-us")}
            className="hover:opacity-50 transition"
          >
            Whitepaper
          </span>
          <span
            onClick={(e) => handleToggleOpen(e, "roadmap")}
            className="hover:opacity-50 transition"
          >
            Roadmap
          </span>
          <span
            onClick={(e) => {
              e.preventDefault(); // Prevent the default action of following the link
              window.location.href = "https://www.t.me/lumwave"; // Redirect to Twitter
            }}
            className="hover:opacity-50 transition cursor-pointer"
          >
            Telegram
          </span>
          <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
            <div className="relative">
              {openMenu && (
                <ul className="w-full absolute bg-white text-black rounded-md shadow-lg py-3 -bottom-3 translate-y-full z-20">
                  <li
                    onClick={() => {
                      handleToggleOpen();
                      setOpenMenu(false);
                    }}
                    className="px-5 py-1 cursor-pointer"
                  >
                    Telegram
                  </li>
                  <li
                    onClick={() => {
                      handleToggleOpen();
                      setOpenMenu(false);
                    }}
                    className="px-5 py-1 cursor-pointer"
                  >
                    Facebook
                  </li>
                  <li
                    onClick={() => {
                      handleToggleOpen();
                      setOpenMenu(false);
                    }}
                    className="px-5 py-1 cursor-pointer"
                  >
                    Instagram
                  </li>
                  <li
                    onClick={() => {
                      handleToggleOpen();
                      setOpenMenu(false);
                    }}
                    className="px-5 py-1 cursor-pointer"
                  >
                    Twitter
                  </li>
                </ul>
              )}
            </div>
          </OutsideClickHandler>
        </div>
        <div className="flex items-center gap-12">
          <div
            className="w-full max-w-[440px] h-[66px] relative cursor-pointer rounded-[17px] overflow-hidden hover:opacity-50 transition"
            style={{ boxShadow: "0 30px 50px #00000070" }}
          >
            <img
              src={ProfilBg}
              className="absolute w-full h-full top-0 left-0"
            />
            <a href="https://lobstr.co/trade/LMW:GCGHWWNTL37TOPWJGA7PPCY3LBEDEFII56HPONQURYTLP6SB76CVELMW" target="_blank" rel="noopener noreferrer">
              <div className="w-full h-full flex items-center relative uppercase gap-5 pl-3.5 pr-6 bg-[#287B5CCF] z-10">
                <Logo width={48} color="#59DBB5" />
                <div className="w-full">
                  <p className="text-base">
                    <span className="fot-semibold tracking-[.2em]">lumwave</span>
                    <span className="inline-block w-[1px] h-[13px] bg-white mx-3"></span>
                    <span className="font-light">LMW</span>
                  </p>
                  <p className="flex justify-between text-sm">
                    <span className="font-light">0.0003813 usd</span>
                    <span className="text-[#59DBB5]">+ 3.10%</span>
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
